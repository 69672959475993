<template>
  <section class="m-wallet m-scroll">
    <!-- 头部导航 -->
    <div class="header">
        <img @click="$router.go(-1)" src="@/assets/public/icon-fanhui_fff.png" alt="">
        <p>我的钱包</p>
    </div>
    <div class="header-bg"></div>

    <!-- 钱包金额 -->
    <div class="money">
        <div class="sum flex-item">
            <p>余额($)</p>
            <p>{{detail.amount}}</p>
        </div>
        <div class="income">
            <div class="flex-item">
                <p>奖励余额($)</p>
                <p>{{detail.non_withdrawal_amount}}</p>
            </div>
            <div class="line"></div>
            <div class="flex-item">
                <p>充值余额($)</p>
                <p>{{detail.withdrawal_amount}}</p>
            </div>
        </div>
    </div>
    
    <!-- 申请提现 -->
    <div class="btn-wrap">
      <van-button  round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,100,72,1) 0%, rgba(240,60,24,1) 100%)"
      @click="recharge">充值</van-button>
    </div>

    <!-- 服务 -->
    <div class="service">
        <p class="title">
            <span></span>
            <span>服务</span>
        </p>
        <div class="service-wrap">
            <!-- <router-link to="/zh/wallet/cardpackage" class="service-item service-border">
                <img src="@/assets/wallet/icon_kabao@2x.png" alt="">
                <span>我的卡包</span>
            </router-link>
            <div class="line line-margin"></div> -->
            <router-link to="/zh/wallet/record" class="service-item service-border">
                <img src="@/assets/wallet/icon_tixian@2x.png" alt="">
                <span>钱包明细</span>
                <div style="flex:1"></div>
                <img src="@/assets/wallet/down.png" alt="">
            </router-link >
        </div>
    </div>
 





  </section>
</template>

<script>

import { wallet } from '@/api/zh/mine.js'
export default {
  name:'Wallet',
  data(){
    return {
      detail: {
        amount: "0.00",
        non_withdrawal_amount: "0.00",
        total_amount: "0.00",
        withdrawal_amount: "0.00",
      }
    }
  },


  methods:{

    handleBack(){
      this.$router.go(-1)
    },

    getData() {
      wallet().then(res => {
        if(res) {
          this.detail = res.data
        }
      })
    },
    recharge() {
      this.$notify({ type: 'warning', message: '暂未开启充值活动！' });
    }
    
  },

  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
@import './wallet.less';
</style>